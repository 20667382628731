import React from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import { Box, Flex, Text, Button } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { BgImage } from 'gbimage-bridge';

import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';

const StyledBgImage = styled(BgImage)`
  background-size: auto;
  background-position: center center;
  margin: 0px -20px;
`;

const camelCase = require('camelcase');

const NotFoundPage = () => {
  const { notFound } = useStaticQuery(graphql`
    {
      notFound: allFile(filter: { relativeDirectory: { eq: "images/404" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED, formats: [PNG])
          }
          name
        }
      }
    }
  `);
  const notFoundImages = {};
  notFound.nodes.forEach(img => {
    notFoundImages[camelCase(img.name)] = { ...img.childImageSharp.gatsbyImageData };
  });

  return (
    <>
      <MetaData title='404' />
      <Layout selected='404'>
        <Box className='full-width'>
          <StyledBgImage image={notFoundImages.yellowLine} alt='Not Found Image'>
            <Box className='body-layout'>
              <Flex flexWrap='wrap' my='75px'>
                <Box width={[1, 1, 1 / 3]} px='20px' mb={['50px', '50px', '0px']}>
                  <Text
                    color='blue400'
                    fontSize={['100px', '200px']}
                    fontWeight='500'
                    letterSpacing='-1px'
                    mt={['0px', '40px']}>
                    404
                  </Text>
                  <Text
                    color='nextonOrange'
                    fontSize={['40px', '54px']}
                    fontWeight='500'
                    letterSpacing='-1px'
                    mt='12px'
                    mb={['40px', '45px']}>
                    we couldn't find anything here
                  </Text>
                  <GatsbyLink to='/'>
                    <Button variant='newMainBold'>GO BACK TO THE HOMEPAGE</Button>
                  </GatsbyLink>
                </Box>
                <Box width={[1, 1, 2 / 3]} px='20px'>
                  <GatsbyImage image={notFoundImages.illustration404} alt='Not Found 404 Image' />
                </Box>
              </Flex>
            </Box>
          </StyledBgImage>
        </Box>
      </Layout>
    </>
  );
};

export default NotFoundPage;
